import { TReadParamsProperties } from '@/shared/api';

import { ResourceService } from '../base-services/resource-service';

export const PagesService = ResourceService('pages');

export const RedirectsService = ResourceService('redirects');

export const getCeoPage = async (slug: string, locale: string): Promise<TCeoPage | null> => {
    if (slug && locale) {
        return await PagesService.readOne<TCeoPage, TReadParamsProperties>({
            identifier: `${slug}/${locale}`,
        });
    }

    return null;
};

export const getOneRedirect = async (slug: string): Promise<TRedirect | null> => {
    if (slug) {
        const response = await RedirectsService.read<{ data: TRedirect[] }, TReadParamsProperties>({
            params: {
                source: `${slug}`,
            },
        });

        return response?.data[0] ?? null;
    }

    return null;
};
