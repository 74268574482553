import { JsonldOfferCatalog, TProductData } from '@dengigroup/seo-jsonld-markup';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import React from 'react';

import { CategoryProducts } from '@/components/modules/category-products';
import { SearchingEmpty } from '@/components/modules/searching-empty';
import { useAppSelector } from '@/store/hooks';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { HowToUse } from '@/entities/info';
import { HeadMeta } from '@/entities/meta-seo';
import { TCategoryProduct, TRentProduct } from '@/entities/product';

import { TMeta } from '@/shared/api';
import { BASE_URL } from '@/shared/constants/paths';
import { useTranslate } from '@/shared/hooks/use-translate';
import { Breadcrumbs } from '@/shared/ui/breadcrumbs';
import { Layout } from '@/shared/ui/layout';
import { PageSection } from '@/shared/ui/page-section';

type TProps = {
    title: string;
    category: TCategoryProduct;
    description: string;
    products: TRentProduct[];
    metaProducts: TMeta;
};

const CategoryProductsPage: NextPage<TProps> = ({ title, description, products = [], metaProducts, category }) => {
    const { t } = useTranslate();

    const categoryName = category.alternativeName ?? category.name;

    const breadcrumbCategoryName = category.name ? `${category.name} ${t('в аренду')}` : products[0].category.name;

    const h1CategoryName = categoryName ?? products[0].category;

    const route = useRouter();

    const seoLinkedProducts: TProductData[] = products.map((product) => {
        return {
            price: product.prices?.priceForThirtyDays?.toString() ?? '',
            image: product.photos?.[0]?.image ?? '',
            urlCard: `/product/${product.id}`,
            name: product.name,
            priceCurrency: 'KZT',
            inStock: product.status === 'active',
        };
    });

    const cityUser = useAppSelector((state) => state.user.cityUser);

    return (
        <Layout header={<Header />} footer={<Footer />}>
            <HeadMeta title={title} description={description} />
            <JsonldOfferCatalog url={route.asPath} data={seoLinkedProducts} name={title} description={description} />
            <Layout.Content>
                {products.length === 0 ? (
                    <SearchingEmpty
                        customTitle="К сожалению не смогли найти такие товары."
                        customSubtitle={t('Пожалуйста, воспользутесь поиском')}
                    />
                ) : (
                    <>
                        <Breadcrumbs
                            breadcrumbs={[
                                {
                                    name: t('Главная страница'),
                                    url: BASE_URL,
                                },
                                {
                                    name: t('Каталог аренды'),
                                    url: `/${cityUser.slug}`,
                                },
                                {
                                    name: breadcrumbCategoryName,
                                    url: '',
                                },
                            ]}
                        />
                        <HowToUse />
                        <PageSection title={h1CategoryName} tagTitle="h1">
                            <CategoryProducts products={products} metaProducts={metaProducts} />
                        </PageSection>
                    </>
                )}
            </Layout.Content>
        </Layout>
    );
};

export default CategoryProductsPage;
