import { styled } from 'styled-components';

export const Wrapper = styled.div`
    a {
        display: block;

        > span {
            display: flex;
            gap: 10px;
            align-items: center;

            span {
                :first-child {
                    font-size: 14px;
                    font-weight: 500;
                }

                :last-child {
                    margin-top: -1px;
                }
            }

            .count {
                border-radius: 12px;
                padding: 5px 6px;
                text-align: center;
                background: ${({ theme }) => theme.color.dark};
                color: ${({ theme }) => theme.color.secondary};
                font-size: 12px;
                font-weight: 500;
                transition: background 0.3s ease-in;
                display: flex;
                justify-content: center;
            }
        }
    }
`;
