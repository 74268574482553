/*
 * Заменяет в строке определенные ключи данных другими данными.
 * Пример: "Привет, world" ; указали ключ - world(с помощью регулярки), а data = "Мир". Итог - "Привет, Мир"
 */

export function changeKeyInStringByData<
    T extends (readonly [] | readonly string[]) & (number extends T['length'] ? readonly [] : unknown),
    T2 extends (readonly [] | readonly string[]) & (number extends T2['length'] ? readonly [] : unknown),
>(str: T2, key: T, data: { [K in keyof T]: string }) {
    const stringArray = [...str] as string[];

    key.forEach((el, i) => {
        stringArray.forEach((value, index) => {
            stringArray[index] = value?.replace(new RegExp(`${el}`, 'gi'), data[i]);
        });
    });

    return stringArray as T2;
}
