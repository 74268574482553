import React, { FC } from 'react';

import { useAppSelector } from '@/store';
import { TSlider } from '@/types/sliders';

import { TArticle, ArticlePage } from '@/entities/article';
import { HeadMeta, TMetaDataReturned } from '@/entities/meta-seo';
import { TRentProduct } from '@/entities/product';
import { TQuestion } from '@/entities/question';

import { TMeta } from '@/shared/api';
import { changeKeyInStringByData } from '@/shared/utils/change-key-in-string-by-data';

import { TCategoryProducts, TDynamicContentTemplateProps, TMainCategoryProps, TSeoPageProps } from '../types';

import { CategoryMainPage } from './category-main-page';
import CategoryProductsPage from './category-product-page';
import { MainPageWithArticle } from './main-page-with-article';

export const DynamicContent: FC<TDynamicContentTemplateProps> = ({ data, metaData, type }) => {
    const { articles, questions, sliders, page } = data as TSeoPageProps;
    const { products, metaProducts, category } = data as TCategoryProducts;
    const { firstCategoryProducts, secondCategoryProducts, anotherCategories, popularProducts } =
        data as TMainCategoryProps;
    const article = (data as TSeoPageProps).page as TArticle;
    const cityName = useAppSelector((state) => state.user.cityUser.name);

    const [title, metaDescription, metaKeywords] = changeKeyInStringByData(
        [metaData?.title ?? article?.title ?? '', article?.metaDescription ?? '', article?.metaKeywords ?? ''],
        ['\\*city\\*'],
        [cityName],
    );

    switch (type) {
        case 'category-main':
            return (
                <CategoryMainPage
                    anotherCategories={anotherCategories}
                    firstCategoryProducts={firstCategoryProducts}
                    popularProducts={popularProducts}
                    secondCategoryProducts={secondCategoryProducts}
                    metaData={metaData as TMetaDataReturned}
                />
            );

        case 'category-products':
            return (
                <CategoryProductsPage
                    description={metaData?.description as string}
                    products={products as TRentProduct[]}
                    title={metaData?.title as string}
                    category={category}
                    metaProducts={metaProducts as TMeta}
                />
            );

        case 'index':
            return (
                <MainPageWithArticle
                    articles={articles as TArticle[]}
                    questions={questions as TQuestion[]}
                    products={popularProducts as TRentProduct[]}
                    articleText={(page as TCeoPage).content}
                    articleTitle={(page as TCeoPage).title}
                    metaDescription={(metaData as TMetaDataReturned).description}
                    metaTitle={(metaData as TMetaDataReturned).title}
                    sliders={sliders as TSlider[]}
                />
            );

        default:
            return (
                <>
                    <HeadMeta
                        title={article.title ? article.title : title}
                        description={metaDescription}
                        keywords={metaKeywords}
                    />
                    <ArticlePage article={article} isShowBack={false} />
                </>
            );
    }
};
