import { styled } from 'styled-components';

import { defaultSettingsHtml, mq } from '@/shared/ui/styled';

export const Wrapper = styled.div`
    position: relative;
    margin-top: -31px;

    .image {
        height: 242px;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        background-position: 50%;
        ${mq.lt.md} {
            height: 200px;
        }
    }

    .link-root {
        position: absolute;
        top: 35px;

        a {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: ${({ theme }) => theme.color.dark};
        }
    }

    .title {
        margin-top: 64px;
        max-width: 468px;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        color: ${({ theme }) => theme.color.dark};
        margin-bottom: 32px;
        ${mq.lt.md} {
            margin-top: 32px;
            font-size: ${({ theme }) => theme.fontSize.xl};
        }
    }

    & .text {
        ${defaultSettingsHtml};
    }
`;
