import { JsonldProductItemList, TProductData } from '@dengigroup/seo-jsonld-markup';
import React, { FC } from 'react';

import { AnotherCategories } from '@/components/modules/another-categories';
import { CategorySlider } from '@/components/modules/category-slider';
import { CategoryLinkPageSection } from '@/components/modules/dynamic-pages/ui/category-link-page-section';
import { useAppSelector } from '@/store/hooks';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { HowToUse } from '@/entities/info';
import { HeadMeta, TMetaDataReturned } from '@/entities/meta-seo';
import { TCategoryProduct, TRentProduct, PopularProducts } from '@/entities/product';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Heading } from '@/shared/ui/heading';
import { Layout } from '@/shared/ui/layout';
import { PageSection, PageSectionWithAnotherTitle } from '@/shared/ui/page-section';

import { TProductsCategoryLocal } from '../../types';

type TProps = {
    metaData: TMetaDataReturned;
    popularProducts: TRentProduct[];
    firstCategoryProducts: TProductsCategoryLocal;
    secondCategoryProducts: TProductsCategoryLocal;
    anotherCategories: TCategoryProduct[];
};

export const CategoryMainPage: FC<TProps> = ({
    metaData,
    popularProducts = [],
    firstCategoryProducts,
    secondCategoryProducts,
    anotherCategories = [],
}) => {
    const { t } = useTranslate();

    const cityUser = useAppSelector((state) => state.user.cityUser);

    const seoLinkedProducts: TProductData[] = popularProducts?.map((product) => {
        return {
            price: product.prices?.priceForThirtyDays?.toString() ?? '',
            image: product.photos?.[0]?.image ?? '',
            name: product.name,
            priceCurrency: 'KZT',
            inStock: product.status === 'active',
        };
    });

    return (
        <Layout header={<Header />} footer={<Footer />}>
            <HeadMeta title={metaData.title} description={metaData.description} />
            {popularProducts?.length > 0 && (
                <JsonldProductItemList data={seoLinkedProducts} url={`/${cityUser.slug}`} />
            )}
            <Layout.Content>
                <Heading as="h1" marginB="42" marginBMobile="16">
                    {t('Каталог аренды')}
                </Heading>
                <HowToUse />
                <PageSection title={t('Самые популярные 🔥')}>
                    <PopularProducts products={popularProducts} />
                </PageSection>
                <>
                    {firstCategoryProducts?.category && (
                        <PageSection
                            title={t(firstCategoryProducts.category)}
                            linkComponent={
                                <CategoryLinkPageSection
                                    title={`${t('Все')} ${firstCategoryProducts.category.toLowerCase()}`}
                                    count={firstCategoryProducts.totalItems}
                                    href={`/${cityUser.slug}/${firstCategoryProducts.slug}`}
                                />
                            }
                            className="hideByMd"
                        >
                            <CategorySlider id="first-category" products={firstCategoryProducts?.products} />
                        </PageSection>
                    )}
                    {secondCategoryProducts?.category && (
                        <PageSection
                            title={t(secondCategoryProducts?.category)}
                            linkComponent={
                                <CategoryLinkPageSection
                                    title={`${t('Все')} ${secondCategoryProducts.category.toLowerCase()}`}
                                    count={secondCategoryProducts.totalItems}
                                    href={`/${cityUser.slug}/${secondCategoryProducts.slug}`}
                                />
                            }
                            className="hideByMd"
                        >
                            <CategorySlider id="second-category" products={secondCategoryProducts?.products} />
                        </PageSection>
                    )}
                </>

                <PageSectionWithAnotherTitle
                    changeBy="md"
                    deskTitle={t('Остальные категории 💫')}
                    mobTitle={t('Категории для аренды')}
                    title=""
                >
                    <AnotherCategories categories={anotherCategories} />
                </PageSectionWithAnotherTitle>
            </Layout.Content>
        </Layout>
    );
};
