import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getOneRedirect, TStatusCode } from '@/shared/api';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';

import { TRedirectResponse } from './types';

export const findRedirect = async (slug: string): Promise<TStatusCode | TRedirectResponse> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const foundRedirectRoute = await getOneRedirect(slug);

        if (foundRedirectRoute && foundRedirectRoute.target && foundRedirectRoute.active) {
            let target = '';
            if (foundRedirectRoute.target.startsWith('http')) {
                target = foundRedirectRoute.target;
            } else {
                target =
                    foundRedirectRoute.target[0] === '/' ? foundRedirectRoute.target : `/${foundRedirectRoute.target}`;
            }

            const targetEncode = `${/[а-яА-ЯЁё\s]/i.test(target) ? encodeURIComponent(target) : target}`;

            return {
                target: targetEncode,
                statusCode,
            };
        }

        statusCode = NOT_FOUND_PAGE;
    } catch (err) {
        const error = err as AxiosError;
        captureException(error);
        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return { statusCode };
};
