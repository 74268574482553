import { Grid } from 'antd';
import React, { FC } from 'react';

import { TCategoryProduct } from '@/entities/product';

import { divideArrayElementsToArrayOfArrayElements } from '@/shared/utils/divide-array-objects-to-array-of-array-objects';

import { Tiles } from './items/tiles';
import { AnotherCategoriesMobile } from './mobile';
import { StyledRow } from './styled';
import { getCategoriesOrderConfig } from './utils/get-categories-order-config';

const { useBreakpoint } = Grid;

type TProps = {
    categories: TCategoryProduct[];
};

export const AnotherCategories: FC<TProps> = ({ categories }) => {
    const categoriesLocal = divideArrayElementsToArrayOfArrayElements(categories.slice(2, categories.length), 5);

    const screen = useBreakpoint();

    if (screen?.md === false) return <AnotherCategoriesMobile categories={categories} />;

    return (
        <nav>
            {categoriesLocal.map((items: TCategoryProduct[], i: number) => {
                const displayType = (i + 1) % 2 === 0 ? 'even' : 'odd';

                return (
                    <StyledRow gutter={[24, 16]} key={i}>
                        <Tiles config={getCategoriesOrderConfig(items, displayType === 'odd', screen?.xl)} />
                    </StyledRow>
                );
            })}
        </nav>
    );
};
