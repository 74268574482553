import { Row } from 'antd';
import { styled } from 'styled-components';

import { mq, rowBothMiddle } from '@/shared/ui/styled';

export const StyledAnotherCategoriesList = styled.ul`
    margin-bottom: 17px;
`;

export const StyledAnotherCategoriesListItem = styled.li`
    height: 100%;
    background: ${({ theme }) => theme.color.secondary};
    position: relative;

    h3 {
        font-weight: 700;
    }

    i {
        cursor: pointer;
        ${rowBothMiddle};
        border-radius: 70px;
        background-color: ${({ theme }) => theme.color.primary};
    }

    div {
        position: absolute;
        ${rowBothMiddle};

        img {
            min-width: initial !important;
            width: initial !important;
        }
    }

    ${mq.lt.md} {
        h3 {
            margin-bottom: 12px;
            font-size: ${({ theme }) => theme.fontSize.md};
        }

        i {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 24px;
            height: 24px;
        }

        div {
            position: relative;
            max-width: 96px;
            height: 89px;

            img {
                min-width: initial !important;
                width: initial !important;
                max-height: 96px;
            }
        }
    }

    ${mq.lt.sm} {
        h3 {
            font-size: ${({ theme }) => theme.fontSize.sm};
        }
    }
`;

export const StyledRow = styled(Row)`
    margin-bottom: 16px;
`;
