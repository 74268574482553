import React, { FC } from 'react';

import { TSlider } from '@/types/sliders';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { Categories } from '@/features/categories';

import { PopularArticles, TArticle, MainPageArticles } from '@/entities/article';
import { HowToUse, Benefits } from '@/entities/info';
import { HeadMeta } from '@/entities/meta-seo';
import { TRentProduct, PopularProducts } from '@/entities/product';
import { QuestionsList, TQuestion } from '@/entities/question';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Layout } from '@/shared/ui/layout';
import { LinkPageSection } from '@/shared/ui/link-page-section';
import { PageSection } from '@/shared/ui/page-section';
import { Slider } from '@/shared/ui/slider';

type TProps = {
    products: TRentProduct[];
    questions: TQuestion[];
    articles: TArticle[];
    articleTitle: string;
    articleText: string;
    metaTitle: string;
    metaDescription: string;
    sliders: TSlider[];
};

export const MainPageWithArticle: FC<TProps> = ({
    articles,
    articleText,
    articleTitle,
    questions,
    products,
    metaDescription,
    metaTitle,
    sliders,
}) => {
    const { t } = useTranslate();

    return (
        <Layout header={<Header />} footer={<Footer />}>
            <HeadMeta title={metaTitle} description={metaDescription} />
            <Layout.Content>
                <Slider sliders={sliders} />
                <HowToUse />
                <MainPageArticles title={articleTitle} text={articleText} />
                <Categories />
                <PageSection title={t('Самые популярные 🔥')}>
                    <PopularProducts products={products} />
                </PageSection>
                <Benefits />
                <PageSection
                    title={t('Самые частые вопросы')}
                    linkComponent={<LinkPageSection title={t('Все вопросы')} href="/" />}
                >
                    <QuestionsList questions={questions} />
                </PageSection>
                <PageSection
                    title={t('Полезные материалы')}
                    linkComponent={<LinkPageSection title={t('Смотреть все')} href="/" />}
                >
                    <PopularArticles articles={articles} />
                </PageSection>
            </Layout.Content>
        </Layout>
    );
};
