import { styled } from 'styled-components';

import { defaultSettingsHtml, mq } from '@/shared/ui/styled';

export const Wrapper = styled.div`
    padding: 19px 24px;
    background: ${({ theme }) => theme.color.secondary};
    border-radius: 12px;
    margin-top: 24px;

    ${mq.lt.md} {
        padding: 19px 12px;
    }
`;

export const Title = styled.h1`
    font-size: ${({ theme }) => theme.fontSize.xl};
    margin-bottom: 16px;
    font-weight: 700;
`;

export const Text = styled.div<{ showAll?: boolean }>`
    display: flex;
    align-items: flex-end;

    & > div > div {
        margin-right: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: ${({ showAll }) => (showAll ? 'initial' : 5)};
        -webkit-box-orient: vertical;
        ${defaultSettingsHtml};
    }

    & > div {
        flex: 1;
    }

    & .openText {
        color: ${({ theme }) => theme.color.dark};
    }

    ${mq.lt.md} {
        flex-direction: column;
        align-items: flex-start;

        & > div > div {
            font-size: ${({ theme }) => theme.fontSize.sm};
            margin-right: 0;
            margin-bottom: 24px;
        }
    }
`;
