/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/*
 * Данная функция делить массив из элементов, на массив из массивов элементов
 * количество элементов, сколько будет в массивах определяет divider
 * пример [1, 2, 3, 4, 5] при divider=1; получиться [[1], [2], [3], [4], [5]]
 */
export const divideArrayElementsToArrayOfArrayElements = (array: any, divider: number) => {
    const newArray = [];

    const max = Math.ceil(array.length / divider);

    for (let i = 0; i < max; i++) {
        newArray.push(array.slice(i * divider, (i + 1) * divider));
    }

    return newArray;
};
