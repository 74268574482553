import { styled } from 'styled-components';

import { mq, rowBothMiddle } from '@/shared/ui/styled';

export const Title = styled.h3<{ $isBig?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: ${({ $isBig, theme }) => ($isBig ? theme.fontSize.xl : theme.fontSize.lg)};
    z-index: 2;
    max-width: 60%;
    ${mq.lt.md} {
        font-size: ${({ theme }) => theme.fontSize.md};
    }

    ${mq.lt.sm} {
        font-size: ${({ theme }) => theme.fontSize.sm};
    }
`;

export const Content = styled.div<{ $isBig?: boolean }>`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;

    & i {
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: ${({ $isBig }) => ($isBig ? '64px' : '38px')};
        height: ${({ $isBig }) => ($isBig ? '64px' : '38px')};
        cursor: pointer;
        ${rowBothMiddle};
        border-radius: 70px;
        background-color: ${({ theme }) => theme.color.primary};
    }
`;

export const Wrapper = styled.li<{ $isBig?: boolean }>`
    height: ${({ $isBig }) => ($isBig ? '298px' : '141px')};
    padding: ${({ $isBig }) => ($isBig ? '32px' : '16px')};
    background: ${({ theme }) => theme.color.secondary};
    position: relative;
    width: 100%;
`;
