import React from 'react';

import { TSvgIcon } from '../types';

export const ArrowLeftNavigateIcon: TSvgIcon = ({ color, width = 8 }): JSX.Element => {
    return (
        <svg width={width} viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9998 0.333313L12.8798 2.21331L5.43984 9.66665H21.6665V12.3333H5.43984L12.8798 19.7866L10.9998 21.6666L0.33317 11L10.9998 0.333313Z"
                fill={color}
            />
        </svg>
    );
};
