import { TCategoryProduct } from '@/entities/product';

import { TCategoriesConfig } from '../types';

export const getCategoriesOrderConfig = (
    items: TCategoryProduct[],
    isSectionOdd: boolean,
    isXl?: boolean,
): TCategoriesConfig => {
    switch (items.length) {
        case 5:
        case 4: {
            if (!isXl || isSectionOdd) {
                return { left: items[0], right: items.slice(1, items.length), multiline: true };
            }

            return { left: items.slice(0, items.length - 1), right: items[items.length - 1], multiline: true };
        }

        case 3: {
            if (isSectionOdd) {
                return { left: items[0], right: items.slice(1, items.length), multiline: false };
            }

            return { left: items.slice(0, items.length - 1), right: items[items.length - 1], multiline: false };
        }

        case 2: {
            return { left: items[0], right: items[1], multiline: false };
        }

        case 1:
        default: {
            return items[0];
        }
    }
};
