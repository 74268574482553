import { NextPage, NextPageContext } from 'next';
import React from 'react';

import {
    DynamicContent,
    getDynamicContentTemplate,
    TDynamicContentTemplateProps,
} from '@/components/modules/dynamic-pages';
import { wrapperReduxToolkit } from '@/store';

import { CatchingErrorLayout } from '@/shared/ui/layout/catching-error';

const DynamicPageRouter: NextPage<TDynamicContentTemplateProps> = ({ data, statusCode, metaData, type }) => {
    return (
        <CatchingErrorLayout statusCode={statusCode}>
            <DynamicContent type={type} statusCode={statusCode} metaData={metaData} data={data} />
        </CatchingErrorLayout>
    );
};

export default DynamicPageRouter;

DynamicPageRouter.getInitialProps = wrapperReduxToolkit.getInitialPageProps(
    ({ dispatch, getState }) =>
        async (context: NextPageContext) => {
            const { res } = context;

            const response = await getDynamicContentTemplate({
                context,
                getState,
                dispatch,
            });

            if (res) {
                res.statusCode = response.statusCode;
            }

            return response;
        },
);
