import { AxiosError } from 'axios';

import { TSeoPageProps } from '@/components/modules/dynamic-pages/types';

import { getArticles } from '@/entities/article';
import { getQuestions } from '@/entities/question';

import { getSliders } from '@/shared/api';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';

export const getSeoPageProps = async (query: string, locale: string, data: TCeoPage): Promise<TSeoPageProps> => {
    let statusCode = STATUS_CODE_OK;

    if (data.templateType === 'index') {
        try {
            const response = await Promise.all([
                getQuestions(locale, true),
                getArticles(1, locale, undefined, true),
                getSliders(locale),
            ]);

            return {
                questions: response[0].data,
                articles: response[1].data,
                sliders: response[2].data,
                statusCode,
                page: data,
            };
        } catch (err) {
            const error = err as AxiosError;

            statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
        }
    } else {
        return { page: data, statusCode };
    }

    return { statusCode, page: null };
};
