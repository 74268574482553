import React, { FC } from 'react';

import { useAppSelector } from '@/store/hooks';

import { TCategoriesConfig } from '../types';

import { Tile } from './tile';

type TProps = {
    config: TCategoriesConfig;
};

export const Tiles: FC<TProps> = ({ config }) => {
    const cityUser = useAppSelector((state) => state.user.cityUser);

    if ('id' in config) {
        return <Tile categories={config} multiline={false} cityUser={cityUser} />;
    }

    return (
        <>
            <Tile categories={config.left} multiline={config.multiline} cityUser={cityUser} />
            <Tile categories={config.right} multiline={config.multiline} cityUser={cityUser} />
        </>
    );
};
