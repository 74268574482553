import { NextPageContext } from 'next';

import { TCity } from '@/components/modules/choose-city';
import {
    TDynamicCategoryProducts,
    TDynamicContentTemplateProps,
    TMainCategoryProps,
    TSeoPageProps,
} from '@/components/modules/dynamic-pages/types';
import { TRootStateType } from '@/store';
import { loadAllCategories } from '@/store/reducers/rent/action-creators';
import { setCityUser } from '@/store/reducers/user/slice';
import { TAppDispatch } from '@/store/types';
import { loadWithPopularProducts } from '@/store/utils/load-with-popular-products';

import { getMetaDataPage, findRedirect } from '@/entities/meta-seo';
import { EStatusProduct, TCategoryProduct, getRentProducts } from '@/entities/product';

import { NOT_FOUND_PAGE, STATUS_CODE_OK } from '@/shared/constants/settings';

import { findCeoPage } from '../api/ceo-page';

import { getDynamicCategoryProducts } from './get-dynamic-category-products';
import { getSeoPageProps } from './seo-page';

type TProps = {
    context: NextPageContext;
    getState: () => TRootStateType;
    dispatch: TAppDispatch;
};

export const getDynamicContentTemplate = async (params: TProps): Promise<TDynamicContentTemplateProps> => {
    const { locale, query, res } = params.context;

    let statusCode = STATUS_CODE_OK;

    // Сперва ищем редирект
    const slug = (query.slug as string[]).join('/');

    const foundRedirect = await findRedirect(slug);

    statusCode = foundRedirect.statusCode;

    if ('target' in foundRedirect && res) {
        res.writeHead(301, {
            location: foundRedirect.target,
        });

        res.end();
    }

    const { getState, dispatch } = params;

    const { user, popularProducts } = await loadWithPopularProducts({
        getState,
        dispatch,
        locale: locale as string,
        context: params.context,
    });

    const state = getState();
    const [slugCity, slugCategory] = query.slug as string[];

    const cities: TCity[] = state.cities.cities.data;

    const categories: TCategoryProduct[] = state.rent.allCategories.data;

    const city = cities.find((e) => e.slug === slugCity);

    const categoryFromSlug = categories.find((e) => e.slug === slugCategory);

    // Ищем диномические посадочные страницы
    if (!city) {
        const ceoPage = await findCeoPage(slug, locale as string);
        statusCode = ceoPage.statusCode;

        if (ceoPage.statusCode === STATUS_CODE_OK) {
            const response = await Promise.all([
                getSeoPageProps(slug as string, locale as string, ceoPage.data as TCeoPage),
                getMetaDataPage(`/${slug}`, locale as string),
            ]);

            return {
                data: { ...(response[0] as TSeoPageProps), popularProducts: popularProducts },
                type: (response[0].page as TCeoPage).templateType,
                metaData: response[1],
                statusCode: response[0].statusCode,
            };
        }
    }

    // Главная страница списка категорий - /{slug_city}
    if (city && (query.slug as string[]).length === 1) {
        await dispatch(loadAllCategories());

        const mainCategories = state.rent.mainCategories.data;

        const allCategories = state.rent.allCategories.data;

        const response = await Promise.all([
            getRentProducts({
                category_id: mainCategories[0]?.id,
                statuses: [EStatusProduct.ACTIVE],
                katoid: user.cityUser.katoid,
            }),
            getRentProducts({
                category_id: mainCategories[1]?.id,
                statuses: [EStatusProduct.ACTIVE],
                katoid: user.cityUser.katoid,
            }),
            getMetaDataPage('/catalog', locale as string),
        ]);

        const data: TMainCategoryProps = {
            popularProducts,
            anotherCategories: allCategories,
            firstCategoryProducts: {
                products: response[0].data,
                totalItems: response[0].meta.totalItems,
                category: mainCategories[0]?.name,
                slug: mainCategories[0]?.slug as string,
            },
            secondCategoryProducts: {
                products: response[1].data,
                totalItems: response[1].meta.totalItems,
                category: mainCategories[1]?.name,
                slug: mainCategories[1]?.slug as string,
            },
        };

        return {
            statusCode: STATUS_CODE_OK,
            type: 'category-main',
            data,
            metaData: response[2],
        };
    }

    // Страницы категорий с городами - /{slug_city}/{slug_category}
    if (!city || !categoryFromSlug) {
        return { statusCode: NOT_FOUND_PAGE };
    }

    await dispatch(setCityUser(city));

    const response = await getDynamicCategoryProducts({
        city,
        page: query?.page as string,
        slugCategory,
        locale: locale as string,
        slugCity,
    });

    statusCode = response.statusCode;

    if (statusCode === STATUS_CODE_OK) {
        return {
            data: {
                products: (response as TDynamicCategoryProducts).products,
                metaProducts: (response as TDynamicCategoryProducts).metaProducts,
                category: (response as TDynamicCategoryProducts).category,
            },
            metaData: (response as TDynamicCategoryProducts).metaData,
            statusCode: response.statusCode,
            type: 'category-products',
        };
    }

    return { statusCode };
};
