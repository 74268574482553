import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { TCity } from '@/components/modules/choose-city';

import { TCategoryProduct } from '@/entities/product';

import { IMAGE_ALT_CUT_LENGTH, DEFAULT_IMAGE_SRC } from '@/shared/constants/settings';
import { ArrowNavigateIcon } from '@/shared/ui/icons';
import { cutString } from '@/shared/utils/string';

import * as Styled from './styled';

type TProps = {
    category: TCategoryProduct;
    cityUser: TCity;
    isBig?: boolean;
};

export const TileBlank: FC<TProps> = ({ cityUser, category, isBig }) => {
    return (
        <Styled.Wrapper $isBig={isBig}>
            <Link href={`/${cityUser.slug}/${category.slug}`}>
                <Styled.Content $isBig={isBig}>
                    <Image
                        src={category?.image ?? DEFAULT_IMAGE_SRC}
                        alt={cutString(category.name, IMAGE_ALT_CUT_LENGTH)}
                        fill
                        style={{ objectFit: 'contain', objectPosition: 'right center' }}
                    />
                    <Styled.Title> {category.name} </Styled.Title>
                    <i>
                        <ArrowNavigateIcon startGradient="#fff" stopGradient="#fff" id="gradiendt1" width={20} />
                    </i>
                </Styled.Content>
            </Link>
        </Styled.Wrapper>
    );
};
