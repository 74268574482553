import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { TCity } from '@/components/modules/choose-city';
import { TDynamicCategoryProducts } from '@/components/modules/dynamic-pages/types';

import { getMetaDataPage } from '@/entities/meta-seo';
import { getRentProducts, getCategoryBySlug, EStatusProduct } from '@/entities/product';

import { TStatusCode } from '@/shared/api';
import { NOT_FOUND_PAGE, SERVER_ERROR, SIZE_SHOWN_PRODUCTS, STATUS_CODE_OK } from '@/shared/constants/settings';
import { changeKeyInStringByData } from '@/shared/utils/change-key-in-string-by-data';

type TParams = {
    slugCity: string;
    slugCategory: string;
    page: string | undefined;
    locale: string;
    city: TCity;
};

export const getDynamicCategoryProducts = async (params: TParams): Promise<TDynamicCategoryProducts | TStatusCode> => {
    const { city, slugCity, slugCategory, page, locale } = params;

    let statusCode = NOT_FOUND_PAGE;

    try {
        const response = await Promise.all([
            getRentProducts({
                category_slug: slugCategory,
                'per-page': SIZE_SHOWN_PRODUCTS,
                statuses: [EStatusProduct.ACTIVE],
                slugs: [slugCity],
                page: page ?? 1,
            }),
            getMetaDataPage(`/catalog/${slugCategory}`, locale as string),
            getCategoryBySlug(slugCategory),
        ]);
        const [title, description] = changeKeyInStringByData(
            [response[1].title, response[1].description],
            ['\\*city\\*'],
            [city.name],
        );

        response[1].title = title;
        response[1].description = description;

        return {
            metaData: response[1],
            products: response[0].data,
            statusCode: STATUS_CODE_OK,
            metaProducts: response[0].meta,
            category: response[2],
        };
    } catch (err) {
        /* eslint-disable no-console */
        console.error('getDynamicCategoryProducts getInitialProps error', err);

        const error = err as AxiosError;

        captureException(error);

        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return { statusCode };
};
