import { captureException } from '@sentry/browser';
import { AxiosError } from 'axios';

import { getCeoPage } from '@/shared/api';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';

export const findCeoPage = async (slug: string, locale: string): Promise<{ data?: TCeoPage; statusCode: number }> => {
    let statusCode = STATUS_CODE_OK;

    try {
        const response = await getCeoPage(slug, locale);

        if (response) {
            return {
                data: response,
                statusCode,
            };
        }
    } catch (err) {
        const error = err as AxiosError;

        if (error.response?.status !== NOT_FOUND_PAGE) {
            captureException(error);
        }

        statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
    }

    return { statusCode };
};
