import React, { FC } from 'react';

import { TRentProduct, ProductsSlider } from '@/entities/product';

import { StyledCategorySlider } from './styled';

type TProps = {
    products: TRentProduct[];
    id?: string;
};

export const CategorySlider: FC<TProps> = ({ products, id = '' }) => {
    return (
        <StyledCategorySlider>
            <ProductsSlider products={products} id={id} />
        </StyledCategorySlider>
    );
};
