import { Col, Row } from 'antd';
import React from 'react';

import { TileBlank } from '@/components/modules/another-categories/items/tile-blank/tile-blank';
import { TCity } from '@/components/modules/choose-city';

import { TCategoryProduct } from '@/entities/product';

type TProps = {
    categories: TCategoryProduct[] | TCategoryProduct;
    cityUser: TCity;
    multiline?: boolean;
};

export const Tile: React.FC<TProps> = ({ categories, multiline, cityUser }) => {
    if (Array.isArray(categories)) {
        return (
            <Col span={24} xl={12}>
                <Row gutter={[24, 18]}>
                    {categories.map((category: TCategoryProduct, i: number) => (
                        <Col span={12} key={i}>
                            <TileBlank category={category} cityUser={cityUser} />
                        </Col>
                    ))}
                </Row>
            </Col>
        );
    }

    return (
        <Col span={24} xl={12}>
            <TileBlank category={categories} cityUser={cityUser} isBig={multiline} />
        </Col>
    );
};
