import { Breadcrumb } from 'antd';
import { styled } from 'styled-components';

import { mq } from '@/shared/ui/styled';

export const StyledBreadcrumbs = styled(Breadcrumb)`
    margin: 16px 0;
    display: flex;

    ol {
        display: flex;
        align-items: center;

        li {
            font-size: ${({ theme }) => theme.fontSize.sm};
            color: ${({ theme }) => theme?.color.dark};
        }

        .ant-breadcrumb-link {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: ${({ theme }) => theme?.color.dark};
            ${mq.lt.sm} {
                white-space: nowrap;
            }
        }

        .ant-breadcrumb-link a {
            color: ${({ theme }) => theme?.color.grey};
            height: auto;
            font-size: 16px;

            &:hover {
                color: ${({ theme }) => theme.color.primary};
            }
        }

        .ant-breadcrumb-separator {
            display: flex;
            align-items: center;
        }
    }
    ${mq.lt.sm} {
        ol {
            flex-wrap: nowrap;
            max-width: 100%;
            overflow: auto;
            display: -webkit-box;

            &::-webkit-scrollbar {
                width: 0;
            }

            li {
                font-size: ${({ theme }) => theme.fontSize.xs};
            }

            span {
                svg {
                    width: 12px;
                }
            }
        }
    }
`;
