import { Col, Row } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';

import { useAppSelector } from '@/store/hooks';

import { Categories } from '@/features/categories';

import { TCategoryProduct } from '@/entities/product';

import { IMAGE_ALT_CUT_LENGTH, DEFAULT_IMAGE_SRC } from '@/shared/constants/settings';
import { ArrowNavigateIcon } from '@/shared/ui/icons';
import { cutString } from '@/shared/utils/string';

import { StyledAnotherCategoriesList, StyledAnotherCategoriesListItem } from './styled';

type TProps = {
    categories: TCategoryProduct[];
};

export const AnotherCategoriesMobile: FC<TProps> = ({ categories }) => {
    const cityUser = useAppSelector((state) => state.user.cityUser);
    return (
        <nav>
            <Categories anotherStyle={true} />
            <StyledAnotherCategoriesList>
                <Row gutter={[12, 12]}>
                    {categories.map(
                        (el: TCategoryProduct, i: number) =>
                            !el.isMain && (
                                <Col span={12} key={i}>
                                    <StyledAnotherCategoriesListItem>
                                        <Link href={`/${cityUser.slug}/${el.slug}`}>
                                            <h3> {el.name} </h3>
                                            <i>
                                                <ArrowNavigateIcon
                                                    startGradient="#fff"
                                                    stopGradient="#fff"
                                                    id="gradiendt1"
                                                    width={12}
                                                />
                                            </i>
                                            <div>
                                                <Image
                                                    src={el.image ?? DEFAULT_IMAGE_SRC}
                                                    alt={cutString(el.name, IMAGE_ALT_CUT_LENGTH)}
                                                    fill
                                                />
                                            </div>
                                        </Link>
                                    </StyledAnotherCategoriesListItem>
                                </Col>
                            ),
                    )}
                </Row>
            </StyledAnotherCategoriesList>
        </nav>
    );
};
