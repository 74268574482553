import { JsonldBreadcrumb } from '@dengigroup/seo-jsonld-markup';
import Link from 'next/link';
import React, { FC } from 'react';

import { TArticle } from '@/entities/article';

import { ARTICLES_URL } from '@/shared/constants/paths';
import { APP_BASE_URL } from '@/shared/constants/settings';
import { useTranslate } from '@/shared/hooks/use-translate';
import { ArrowLeftNavigateIcon } from '@/shared/ui/icons';
import { Layout } from '@/shared/ui/layout';

import * as Styled from './styled';

type TProps = {
    article: TArticle;
    isShowBack?: boolean;
};

export const ArticlePage: FC<TProps> = ({ article, isShowBack = true }) => {
    const { t } = useTranslate();

    return (
        <>
            <JsonldBreadcrumb
                data={[{ name: t('К списку статей'), url: ARTICLES_URL }]}
                domain={APP_BASE_URL as string}
            />
            <Styled.Wrapper>
                <div className="image" style={{ backgroundImage: `url("${article.imageUrl}")` }} />
                <Layout.Content>
                    {isShowBack && (
                        <div className="link-root">
                            <Link href={ARTICLES_URL}>
                                <ArrowLeftNavigateIcon width={15} /> &nbsp;&nbsp; {t('К списку статей')}
                            </Link>
                        </div>
                    )}
                    <h1 className="title">{article.header ?? article.title}</h1>
                    <div className="text">
                        <div dangerouslySetInnerHTML={{ __html: article.content as string }} />
                    </div>
                </Layout.Content>
            </Styled.Wrapper>
        </>
    );
};
