import React, { FC, useState } from 'react';

import { useTranslate } from '@/shared/hooks/use-translate';
import { Button } from '@/shared/ui/button';

import * as Styled from './styled';

export type TProps = {
    title: string;
    text: string;
};

export const MainPageArticles: FC<TProps> = ({ text = '', title }) => {
    const [showAll, setShowAll] = useState<boolean>(false);
    const { t } = useTranslate();
    const handleChangeShow = () => {
        setShowAll((prev) => !prev);
    };

    return (
        <Styled.Wrapper>
            <Styled.Title> {title} </Styled.Title>
            <Styled.Text showAll={showAll}>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
                <Button
                    className="openText"
                    icon="👀"
                    iconPos="right"
                    weight="700"
                    fontSize="xs"
                    onClick={handleChangeShow}
                    aria-label={showAll ? t('Свернуть') : t('Развернуть')}
                >
                    {showAll ? t('Свернуть') : t('Развернуть')}
                </Button>
            </Styled.Text>
        </Styled.Wrapper>
    );
};
