import Link from 'next/link';
import React, { FC, ReactNode } from 'react';

import { ArrowIcon } from '@/shared/ui/icons';

import { StyledBreadcrumbs } from './styled';

export type TBreadcrumb = {
    name: string;
    url: string;
};

type TProps = {
    breadcrumbs: TBreadcrumb[];
    separator?: string | ReactNode;
};

const prepareBreadcrumbs = (items: TBreadcrumb[]): { title: string | ReactNode }[] => {
    return items.map((item, index) => {
        const title = items.length - 1 === index ? item.name : <Link href={item.url}>{item.name}</Link>;
        return { title };
    });
};

export const Breadcrumbs: FC<TProps> = ({ breadcrumbs, separator }) => {
    return (
        <StyledBreadcrumbs separator={separator ?? <ArrowIcon width={8} />} items={prepareBreadcrumbs(breadcrumbs)} />
    );
};
